import Joi from 'joi';
import getConfig from 'next/config';
import { addCleanCache } from '@culture/common-helpers/params';
import {
	api as apiRequest,
	batchApi as batchApiRequest
} from '@culture/common-helpers/request';

import MainTemplate from 'pageTemplates/main';

const Main = (props) => <MainTemplate {...props} />;

const { publicRuntimeConfig } = getConfig();

const { pushkinCardMainRubricPath } = publicRuntimeConfig.entities;

const getRubricPage = (rubricPath, cleanCache) =>
	apiRequest(
		'rubricPages/page',
		addCleanCache(
			{
				rubricPath,
				blockFields: {
					publications: ['title', 'type', 'name', 'thumbnailFile'].join(),
					pushkinsCardInstitutes: [
						'title',
						'type',
						'name',
						'thumbnailFile'
					].join()
				},
				completeBlocksParams: [
					{
						blockName: 'publications',
						blockParams: {
							rubrics: [pushkinCardMainRubricPath],
							limit: 10,
							sort: '-publishDate'
						}
					},
					{
						blockName: 'pushkinsCardInstitutes',
						blockParams: {
							limit: 10,
							sort: '-views'
						}
					}
				]
			},
			cleanCache
		)
	);

export async function getServerSideProps({ query }) {
	const validationSchema = Joi.object().keys({
		cleanCache: Joi.boolean()
	});

	const params = Joi.attempt(query, validationSchema, {
		stripUnknown: true
	});

	const [rubricPage] = await batchApiRequest([
		getRubricPage(
			publicRuntimeConfig.entities.pushkinCardMainRubricPath,
			params.cleanCache
		)
	]);

	return {
		props: {
			textBlocks: rubricPage.textBlocks,
			publications: rubricPage.blocks?.publications,
			institutes: rubricPage.blocks?.pushkinsCardInstitutes
		}
	};
}

export default Main;
