import React from 'react';
import PropTypes from 'prop-types';
import { useParallax } from 'react-scroll-parallax';

import BaseSlider from 'components/sliders/BaseSlider';
import Container from 'components/Container';
import PublicationCard from 'components/cards/PublicationCard';
import Section from 'components/Section';
import OpenCatalogContainer from 'components/OpenCatalogContainer';

import { getColorForCard } from 'utils/helpers/endlessColorsList';

import icons from './icons';
import styles from './styles.module.scss';

const colorsList = ['secondary', 'tertiary', 'quaternary'];

const getColor = getColorForCard(colorsList);

const RecommendationsSection = ({ list }) => {
	const waveParallax = useParallax({ speed: 20 });
	const figureGroupParallax = useParallax({ speed: 10 });

	return (
		<Section
			sectionType="main"
			title="«культура.рф» рекомендует"
			titleTheme="secondary"
		>
			<Container gutter="noMobile" className={styles.recomendationContainer}>
				<OpenCatalogContainer title="Перейти в каталог" url="/publications">
					<BaseSlider
						params={{
							spaceBetween: 20,
							slidesPerView: 'auto',
							breakpoints: {
								1280: {
									spaceBetween: 20,
									slidesPerView: 4
								}
							}
						}}
						gutter="onlyMobile"
						showControlsButton
					>
						{list.map((item, index) => (
							<PublicationCard
								key={item?._id}
								sizeType="small"
								entityType="publications"
								entity={{
									...item,
									color: getColor(index)
								}}
								isSlider
							/>
						))}
					</BaseSlider>
				</OpenCatalogContainer>
				<div className={styles.figureWave} ref={waveParallax.ref}>
					{icons.figureWave}
				</div>
				<div className={styles.figureGroup} ref={figureGroupParallax.ref}>
					<div className={styles.figureTriangle}>{icons.figureTriangle}</div>
					<div className={styles.figureCircle}>{icons.figureCircle}</div>
				</div>
			</Container>
		</Section>
	);
};

RecommendationsSection.propTypes = {
	list: PropTypes.array
};

RecommendationsSection.defaulProps = {
	list: []
};

export default RecommendationsSection;
