import React from 'react';

import BaseSlider from 'components/sliders/BaseSlider';
import Container from 'components/Container';
import Section from 'components/Section';
import SizeDependedBlock from 'components/SizeDependedBlock';
import InfoCard from 'components/cards/InfoCard';

import icons from './icons';
import styles from './styles.module.scss';

const infoData = [
	{
		title:
			'Зарегистрируйтесь на портале <a href="https://www.gosuslugi.ru/" target="_blank">«Госуслуги»</a>. Это можно сделать с 14 лет.',
		icon: icons.auth,
		name: 'auth'
	},
	{
		title:
			'Подтвердите учетную запись с помощью <a href="https://www.gosuslugi.ru/help/faq/login/2" target="_blank">онлайн-банков</a> или в <a href="https://map.gosuslugi.ru/map/co?filter=cfm" target="_blank">центрах обслуживания</a>.',
		icon: icons.сonfirm,
		name: 'сonfirm'
	},
	{
		title: 'Скачайте приложение для просмотра афиши событий и остатка средств.',
		icon: icons.app,
		name: 'app'
	},
	{
		title:
			'Подтвердите выпуск и получите виртуальную или пластиковую карту «Мир»',
		icon: icons.card,
		name: 'card'
	}
];

const InfoSection = () => (
	<Section
		sectionType="main"
		title="Как принять участие в программе?"
		titleTheme="tertiary"
	>
		<SizeDependedBlock>
			<BaseSlider params={{ spaceBetween: 40 }} gutter="onlyMobile">
				{infoData.map((item) => (
					<InfoCard
						key={item.title}
						title={item.title}
						icon={item.icon}
						name={item.name}
					/>
				))}
			</BaseSlider>
			<Container>
				<div className={styles.list}>
					{infoData.map((item) => (
						<InfoCard
							key={item.title}
							title={item.title}
							icon={item.icon}
							name={item.name}
						/>
					))}
				</div>
			</Container>
		</SizeDependedBlock>
	</Section>
);

export default InfoSection;
