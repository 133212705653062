import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import styles from './styles.module.scss';

const OpenCatalogContainer = ({ title, url, children }) => {
	if (title && url) {
		return (
			<div>
				{children}
				<div className={styles.linkContainer}>
					<Button title={title} url={url} gutter="large" />
				</div>
			</div>
		);
	}
	return children;
};

OpenCatalogContainer.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	children: PropTypes.object.isRequired
};

export default OpenCatalogContainer;
