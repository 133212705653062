import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import cx from 'classnames';

import styles from './styles.module.scss';

const InfoCard = ({ title, icon, name }) => (
	<div className={styles.infoCard}>
		<div className={cx(styles.icon, styles[`icon_${name}`])}>{icon}</div>
		{parse(title)}
	</div>
);

InfoCard.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.node,
	name: PropTypes.string
};

export default InfoCard;
