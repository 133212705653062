import React from 'react';
import parse from 'html-react-parser';
import { useParallax } from 'react-scroll-parallax';

import Container from 'components/Container';
import Section from 'components/Section';

import icons from './icons';
import styles from './styles.module.scss';

const guideList = [
	{
		title: 'Выберите мероприятие в афише',
		description:
			'Через приложение или на портале <a href="https://www.culture.ru/" target="_blank">Культура.РФ</a>',
		itemNumber: icons.figureOne
	},
	{
		title: 'Оплатите билет Пушкинской картой',
		description:
			'Онлайн в приложении, в кассе или на сайте организации, которая участвует в программе «Пушкинская карта». При оплате выберите вариант «Оплатить Пушкинской картой»',
		itemNumber: icons.figureTwo
	},
	{
		title: 'Посетите мероприятие',
		description:
			'На входе покажите контролеру билет и паспорт или свой профиль в приложении «Госуслуги Культура»',
		itemNumber: icons.figureThree
	}
];

const GuideSection = () => {
	const waveParallax = useParallax({ speed: 20 });

	return (
		<Section
			sectionType="main"
			title="Как посетить мероприятие?"
			titleTheme="primary"
		>
			<Container>
				<div className={styles.Guide}>
					<div className={styles.Guide__List}>
						{guideList.map((item) => (
							<div key={item.title} className={styles.Guide__Item}>
								<div className={styles.Guide__Number}>{item.itemNumber}</div>
								<div>
									<div className={styles.Guide__Title}>{item.title}</div>
									<div className={styles.Guide__Description}>
										{parse(item.description)}
									</div>
								</div>
							</div>
						))}
					</div>
					<div className={styles.Guide__FigureMultiwave} ref={waveParallax.ref}>
						{icons.figureMultiwave}
					</div>
				</div>
			</Container>
		</Section>
	);
};

export default GuideSection;
