import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import { Layout, LayoutBlock } from 'components/Layout';
import AppLinks from 'components/AppLinks';

import InfoSection from './components/InfoSection';
import InstitutesSection from './components/InstitutesSection';
import RecommendationsSection from './components/RecommendationsSection';
import GuideSection from './components/GuideSection';

import styles from './styles.module.scss';

const MainTemplate = ({ textBlocks, publications, institutes }) => (
	<Layout typeHeader="promo" textBlocks={textBlocks}>
		<LayoutBlock>
			<InfoSection />
		</LayoutBlock>
		<LayoutBlock>
			<AppLinks />
		</LayoutBlock>
		<LayoutBlock>
			<GuideSection />
		</LayoutBlock>
		{!_(institutes).isEmpty() && (
			<LayoutBlock className={styles.blockInstitutes}>
				<InstitutesSection list={institutes} />
			</LayoutBlock>
		)}
		{!_(publications).isEmpty() && (
			<LayoutBlock className={styles.blockRecommendations}>
				<RecommendationsSection list={publications} />
			</LayoutBlock>
		)}
	</Layout>
);

MainTemplate.propTypes = {
	textBlocks: PropTypes.object,
	publications: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			thumbnailFile: PropTypes.object.isRequired
		})
	),
	institutes: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			thumbnailFile: PropTypes.object.isRequired
		})
	)
};

export default MainTemplate;
