import React from 'react';
import PropTypes from 'prop-types';
import { useParallax } from 'react-scroll-parallax';

import Container from 'components/Container';
import PublicationCard from 'components/cards/PublicationCard';
import Section from 'components/Section';
import OpenCatalogContainer from 'components/OpenCatalogContainer';
import BaseSlider from 'components/sliders/BaseSlider';
import { getColorForCard } from 'utils/helpers/endlessColorsList';

import icons from './icons';
import styles from './styles.module.scss';

const colorsList = ['tertiary', 'secondary', 'quaternary'];

const getColor = getColorForCard(colorsList);

const InstitutesSection = ({ list }) => {
	const waveParallax = useParallax({ speed: 20 });
	const figureGroupParallax = useParallax({ speed: 10 });

	return (
		<Section
			sectionType="main"
			title="Участники — учреждения культуры"
			titleTheme="quaternary"
		>
			<Container gutter="noMobile" className={styles.instituteContainer}>
				<OpenCatalogContainer
					title="Перейти в каталог"
					url="/institutes-catalog/russia"
				>
					<BaseSlider
						params={{
							spaceBetween: 20,
							slidesPerView: 'auto',
							breakpoints: {
								1280: {
									spaceBetween: 20,
									slidesPerView: 4
								}
							}
						}}
						gutter="onlyMobile"
						showControlsButton
					>
						{list.map((item, index) => (
							<PublicationCard
								key={item?._id}
								sizeType="small"
								entityType="institutes"
								entity={{
									...item,
									color: getColor(index)
								}}
								isSlider
							/>
						))}
					</BaseSlider>
				</OpenCatalogContainer>
				<div className={styles.figureWave} ref={waveParallax.ref}>
					{icons.figureWave}
				</div>
				<div className={styles.figureGroup} ref={figureGroupParallax.ref}>
					<div className={styles.figureCircle}>{icons.figureCircle}</div>
					<div className={styles.figureSquare}>{icons.figureSquare}</div>
				</div>
			</Container>
		</Section>
	);
};

InstitutesSection.propTypes = {
	list: PropTypes.array
};

InstitutesSection.defaulProps = {
	list: []
};

export default InstitutesSection;
