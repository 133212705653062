import React from 'react';

import styles from './styles.module.scss';

export default {
	figureOne: (
		<svg
			width="120"
			height="118"
			viewBox="0 0 120 118"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="55" cy="62.3813" r="55" fill="#E9FB1E" />
			<path
				d="M55 87.3813L65.5624 97.3813L76.1248 87.3813L86.6871 97.3813L97.2495 87.3813L107.812 97.3813L118.374 87.3813"
				stroke="#0C0722"
				strokeWidth="2"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
			<path
				d="M33.5957 0.896973V72.3813H24.5625V12.1763L6.34961 18.8169V10.6626L32.1797 0.896973H33.5957Z"
				fill="black"
			/>
		</svg>
	),
	figureTwo: (
		<svg
			width="116"
			height="118"
			viewBox="0 0 116 118"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="55.4614" cy="62.4517" r="55" fill="#01FFFF" />
			<path
				d="M102.91 62.4517L116 111.304L67.1479 98.2139L102.91 62.4517Z"
				fill="white"
			/>
			<path
				d="M96.2236 63.5995L109.314 112.452L60.4614 99.3618L96.2236 63.5995Z"
				fill="#E9FB1E"
			/>
			<path
				d="M69.0798 97.6962L102.393 64.3835L114.586 109.89L69.0798 97.6962Z"
				stroke="#0C0722"
				strokeWidth="2"
			/>
			<path
				d="M50.9517 65.0298V72.4517H4.41846V65.9575L27.7095 40.0298C30.5741 36.8397 32.7876 34.1379 34.3501 31.9243C35.9451 29.6782 37.0519 27.6763 37.6704 25.9185C38.3215 24.1281 38.647 22.3052 38.647 20.4497C38.647 18.106 38.1587 15.9901 37.1821 14.1021C36.2381 12.1815 34.8384 10.6515 32.9829 9.51221C31.1274 8.37288 28.8813 7.80322 26.2446 7.80322C23.0871 7.80322 20.4504 8.42171 18.3345 9.65869C16.2511 10.8631 14.6886 12.5558 13.647 14.7368C12.6053 16.9178 12.0845 19.4243 12.0845 22.2563H3.05127C3.05127 18.2524 3.93018 14.5903 5.68799 11.27C7.4458 7.94971 10.05 5.31299 13.5005 3.35986C16.951 1.37419 21.1991 0.381348 26.2446 0.381348C30.7368 0.381348 34.578 1.17887 37.7681 2.77393C40.9582 4.33643 43.3996 6.54997 45.0923 9.41455C46.8175 12.2466 47.6802 15.5669 47.6802 19.3755C47.6802 21.4588 47.3221 23.5747 46.606 25.7231C45.9224 27.839 44.9621 29.9549 43.7251 32.0708C42.5207 34.1867 41.1047 36.27 39.4771 38.3208C37.882 40.3716 36.173 42.3898 34.3501 44.3755L15.3071 65.0298H50.9517Z"
				fill="black"
			/>
		</svg>
	),
	figureThree: (
		<svg
			width="116"
			height="118"
			viewBox="0 0 116 118"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="55" cy="62.3813" r="55" fill="#1EFB9E" />
			<path
				d="M16.4531 32.4614H22.8984C26.056 32.4614 28.6602 31.9406 30.7109 30.8989C32.7943 29.8247 34.3405 28.3761 35.3496 26.5532C36.3913 24.6978 36.9121 22.6144 36.9121 20.3032C36.9121 17.5688 36.4564 15.2739 35.5449 13.4185C34.6335 11.563 33.2663 10.1632 31.4434 9.21924C29.6204 8.27523 27.3092 7.80322 24.5098 7.80322C21.9707 7.80322 19.7246 8.30778 17.7715 9.31689C15.8509 10.2935 14.3372 11.6932 13.2305 13.5161C12.1562 15.339 11.6191 17.4875 11.6191 19.9614H2.58594C2.58594 16.3481 3.4974 13.0604 5.32031 10.0981C7.14323 7.13591 9.69857 4.77588 12.9863 3.01807C16.3066 1.26025 20.1478 0.381348 24.5098 0.381348C28.8066 0.381348 32.5664 1.14632 35.7891 2.67627C39.0117 4.17367 41.5182 6.41976 43.3086 9.41455C45.099 12.3768 45.9941 16.0715 45.9941 20.4985C45.9941 22.2889 45.571 24.2095 44.7246 26.2603C43.9108 28.2785 42.625 30.1665 40.8672 31.9243C39.1419 33.6821 36.8958 35.1307 34.1289 36.27C31.362 37.3768 28.0417 37.9302 24.168 37.9302H16.4531V32.4614ZM16.4531 39.8833V34.4634H24.168C28.6927 34.4634 32.4362 35.0005 35.3984 36.0747C38.3607 37.1489 40.6882 38.5812 42.3809 40.3716C44.1061 42.1619 45.3105 44.1313 45.9941 46.2798C46.7103 48.3957 47.0684 50.5116 47.0684 52.6274C47.0684 55.9478 46.4987 58.8937 45.3594 61.4653C44.2526 64.0369 42.6738 66.2179 40.623 68.0083C38.6048 69.7987 36.2285 71.1496 33.4941 72.061C30.7598 72.9725 27.7812 73.4282 24.5586 73.4282C21.4661 73.4282 18.5527 72.9888 15.8184 72.1099C13.1165 71.231 10.724 69.9614 8.64062 68.3013C6.55729 66.6086 4.92969 64.5415 3.75781 62.1001C2.58594 59.6261 2 56.8104 2 53.6528H11.0332C11.0332 56.1268 11.5703 58.2915 12.6445 60.147C13.7513 62.0024 15.3138 63.451 17.332 64.4927C19.3828 65.5018 21.7917 66.0063 24.5586 66.0063C27.3255 66.0063 29.7018 65.5343 31.6875 64.5903C33.7057 63.6138 35.252 62.1489 36.3262 60.1958C37.4329 58.2427 37.9863 55.785 37.9863 52.8228C37.9863 49.8605 37.3678 47.4354 36.1309 45.5474C34.8939 43.6268 33.1361 42.2108 30.8574 41.2993C28.6113 40.3553 25.9583 39.8833 22.8984 39.8833H16.4531Z"
				fill="black"
			/>
			<rect
				x="73.252"
				y="67.3813"
				width="35.7486"
				height="35.7486"
				transform="rotate(15 73.252 67.3813)"
				fill="white"
			/>
			<rect
				x="81.0234"
				y="73.5981"
				width="35.7486"
				height="35.7486"
				transform="rotate(15 81.0234 73.5981)"
				fill="#FB1E9B"
			/>
			<rect
				x="77.0684"
				y="68.6061"
				width="33.7486"
				height="33.7486"
				transform="rotate(15 77.0684 68.6061)"
				stroke="#0C0722"
				strokeWidth="2"
			/>
		</svg>
	),
	figureMultiwave: (
		<svg
			width="257"
			height="39"
			viewBox="0 0 257 39"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={styles.svg}
		>
			<path
				d="M2.26953 1.69824L18.0195 16.6982L33.7695 1.69824L49.5195 16.6982L65.2695 1.69824L81.0195 16.6982L96.7695 1.69824L112.52 16.6982L128.27 1.69824M129.27 1.69824L145.02 16.6982L160.77 1.69824L176.52 16.6982L192.27 1.69824L208.02 16.6982L223.77 1.69824L239.52 16.6982L255.27 1.69824"
				stroke="#FB1E9B"
				strokeWidth="2"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
			<path
				d="M2.26953 22.6982L18.0195 37.6982L33.7695 22.6982L49.5195 37.6982L65.2695 22.6982L81.0195 37.6982L96.7695 22.6982L112.52 37.6982L128.27 22.6982M129.27 22.6982L145.02 37.6982L160.77 22.6982L176.52 37.6982L192.27 22.6982L208.02 37.6982L223.77 22.6982L239.52 37.6982L255.27 22.6982"
				stroke="#FB1E9B"
				strokeWidth="2"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
		</svg>
	)
};
