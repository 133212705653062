import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Container from 'components/Container';
import Line from 'components/Line';
import SectionTitle from 'components/SectionTitle';

import styles from './styles.module.scss';

const Section = ({
	children,
	overflow,
	sectionType,
	showSeparatorLine,
	showTitleFigure,
	title,
	titleDescription,
	titleLevel,
	titleTheme
}) => (
	<section className={cx(overflow && styles.overflow)}>
		<Container
			className={cx(
				styles.sectionContainer,
				sectionType && styles[`sectionContainerType_${sectionType}`]
			)}
		>
			{showSeparatorLine && <Line className={styles.separatorLine} />}
			<SectionTitle
				title={title}
				description={titleDescription}
				theme={titleTheme}
				level={titleLevel}
				type={sectionType === 'catalogPublications' ? sectionType : null}
				showFigure={showTitleFigure}
			/>
		</Container>
		{children}
	</section>
);

Section.propTypes = {
	children: PropTypes.node.isRequired,
	overflow: PropTypes.bool,
	sectionType: PropTypes.oneOf([
		'catalogInstitutes',
		'catalogPublications',
		'main',
		'more'
	]),
	showSeparatorLine: PropTypes.bool,
	showTitleFigure: PropTypes.bool,
	title: PropTypes.string.isRequired,
	titleDescription: PropTypes.string,
	titleLevel: PropTypes.number,
	titleTheme: PropTypes.string
};

Section.defaultProps = {
	showTitleFigure: true,
	showSeparatorLine: false
};

export default Section;
